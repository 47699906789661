.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container p {
  margin: 1rem;
  color: red;
  font-weight: bold;
  font-size: 2rem;
  text-shadow: 4px 4px 2px rgb(243, 169, 8);
  text-align: center;
}

.container img {
  width: 15rem;
  margin: 1rem;
}

.container button {
  border: 0.1rem solid black;
  border-radius: 2rem;
  margin: 1rem;
  width: 5rem;
  height: 2rem;
  background-color: rgb(0, 195, 255);
  font-weight: bold;
}

#button-prev:hover {
  background-color: rgba(71, 255, 141, 0.452);
}

#button-next:hover {
  background-color: rgba(255, 99, 71, 0.452);
}

@media screen and (min-width: 600px) {
  .container p {
    font-size: 4rem;
  }
}
