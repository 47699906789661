.h-pokemon {
    display: flex;
    flex-direction: column;
    align-items: center;
   
}

.h-img {
    width: 20rem;
    padding: 1rem;
}

@media screen and (min-width: 600px) {
    .h-pokemon {
        flex-direction: row;
        justify-content: space-around;
    }
    .h-img {
        width: 25rem;
    }
}